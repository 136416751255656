<script src="../router/index.js"></script>
<template>
    <div class="container">
        <info :post="post"></info>
        <div class="tab">
            <van-tabs sticky animated swipeable color="#02DBCA" v-model="active">
                <van-tab title="个人信息">
                    <van-form @submit="onSubmit('data')">
                        <van-field
                            v-model.trim="dataList.data.name"
                            name="name"
                            label="姓名"
                            input-align="right"
                            placeholder="请输入您的姓名"
                            :rules="[{ pattern :Reg.nameReg,required: true, message: '' }]"
                        />
                        <van-cell
                            title="性别"
                            @click="SelectData('data','gender')"
                            is-link
                            :value="dataList.data.gender"
                        />
                        <van-field
                            v-model.trim="dataList.data.height"
                            name="height"
                            label="身高"
                            type="number"
                            input-align="right"
                            placeholder="请输入您的身高,单位cm"
                            :rules="[{ required: true, message: '' }]"
                        />
                        <van-field
                            v-model.trim="dataList.data.weight"
                            name="weight"
                            label="体重"
                            type="number"
                            input-align="right"
                            placeholder="请输入您的体重,单位kg"
                            :rules="[{ required: true, message: '' }]"
                        />
                        <van-cell
                            title="出生日期"
                            @click="SelectDates('data','birthday')"
                            is-link
                            :value="dataList.data.birthday"
                        />
                        <van-field
                            v-model.trim="dataList.data.phone"
                            name="phone"
                            label="手机号"
                            input-align="right"
                            placeholder="请输入您的手机号"
                            :rules="[{ pattern :Reg.telReg,required: true, message: '' }]"
                        />
                        <van-cell
                            title="政治面貌"
                            is-link
                            @click="SelectData('data','zhengzhi')"
                            :value="dataList.data.zhengzhi"
                        />
<!--                        <van-cell-->
<!--                            title="户口性质"-->
<!--                            is-link-->
<!--                            @click="SelectData('data','hukou')"-->
<!--                            :value="dataList.data.hukou"-->
<!--                        />-->
                        <van-cell
                            title="学历"
                            is-link
                            @click="SelectData('data','xueli')"
                            :value="dataList.data.xueli"
                        />
                      <van-cell
                          title="是否全日制(第一学历)"
                          is-link
                          @click="SelectData('data','xueli_full_time')"
                          :value="dataList.data.xueli_full_time"
                      />
                        <van-cell
                            title="婚姻"
                            is-link
                            @click="SelectData('data','hunyin')"
                            :value="dataList.data.hunyin"
                        />
                        <van-cell
                            title="民族"
                            is-link
                            @click="SelectData('data','minzu')"
                            :value="dataList.data.minzu"
                        />
                        <van-field
                            v-model.trim="dataList.data.identityCardNo"
                            name="identityCardNo"
                            label="身份证号码"
                            input-align="right"
                            placeholder="请输入您的身份证号码"
                            :rules="[{ pattern :Reg.idCardReg,required: true, message: '' }]"
                        />
                        <van-field
                            v-model.trim="dataList.data.idAddress"
                            name="idAddress"
                            type="textarea"
                            autosize
                            rows="1"
                            input-align="right"
                            label="身份证地址"
                            placeholder="请输入您的身份证地址"
                            :rules="[{ required: true, message: '' }]"
                        />
                        <van-cell
                            title="选择地区"
                            @click="SelectArea('data','region')"
                            is-link
                            :value="dataList.data.region"
                        />
                        <van-field
                            v-model.trim="dataList.data.realAddress"
                            name="realAddress"
                            rows="1"
                            autosize
                            label="现住址"
                            input-align="right"
                            type="textarea"
                            placeholder="请输入现住址"
                            :rules="[{ required: true, message: '' }]"
                        />
                        <van-field
                            v-model.trim="dataList.data.emergencyMan"
                            name="emergencyMan"
                            label="紧急联系人"
                            input-align="right"
                            placeholder="请输入紧急联系人的姓名"
                            :rules="[{ pattern :Reg.nameReg,required: true, message: '' }]"
                        />
                        <van-field
                            v-model.trim="dataList.data.emergencyShip"
                            name="emergencyShip"
                            label="双方关系"
                            input-align="right"
                            placeholder="与紧急联系人的关系"
                            :rules="[{ required: true, message: '' }]"
                        />
                        <van-field
                            v-model.trim="dataList.data.emergencyTel"
                            name="emergencyTel"
                            label="对方电话"
                            input-align="right"
                            placeholder="请输入紧急联系人电话"
                            :rules="[{ pattern :Reg.telReg,required: true, message: '' }]"
                        />
                        <van-field
                            v-model.trim="dataList.data.expectedSalary"
                            name="expectedSalary"
                            label="期望薪资"
                            input-align="right"
                            placeholder="请输入您所期望的薪资"
                            :rules="[{ required: true, message: '' }]"
                        />
                        <van-field
                            v-model.trim="dataList.data.acceptedSalary"
                            name="acceptedSalary"
                            label="最低薪资"
                            input-align="right"
                            placeholder="请输入您能最低接受的薪资"
                            :rules="[{ required: true, message: '' }]"
                        />
                        <van-cell
                            title="信息来源"
                            is-link
                            @click="SelectData('data','infoFromKey')"
                            :value="dataList.data.infoFromKey"
                        />
                        <div style="margin: 16px;">
                            <van-button
                                class="btn"
                                block
                                native-type="submit"
                            >下一步</van-button>
                        </div>
                    </van-form>
                </van-tab>
                <van-tab title="教育情况" :disabled="!checkOne">
                    <van-form @submit="onSubmit('data1')">
                        <div class="xlTitle">第一学历</div>
                        <van-field v-model.trim="dataList.data1.name1" name="name1" label="毕业院校" input-align="right"
                            placeholder="请输入您的毕业院校" :rules="[{ required: true, message: '' }]" />
                        <van-cell title="开始时间" is-link @click="SelectDates('data1', 'eduStart1')"
                            :value="dataList.data1.eduStart1" />
                        <van-cell title="结束时间" is-link @click="SelectDates('data1', 'eduEnd1')"
                            :value="dataList.data1.eduEnd1" />
                        <van-cell title="学历" @click="SelectData('data1', 'eduXueli1')" is-link
                            :value="dataList.data1.eduXueli1" />
                        <van-field v-model.trim="dataList.data1.major1" name="major1" label="专业" input-align="right"
                            placeholder="请输入您的专业" :rules="[{ required: true, message: '' }]" />
                        <van-field v-model.trim="dataList.talent" name="major1" label="个人特长" input-align="right"
                            placeholder="请输入个人特长" />
                        <div class="xlTitle">第二学历</div>
                        <van-field v-model.trim="dataList.data3.name1" name="name1" label="毕业院校" input-align="right"
                            placeholder="请输入您的毕业院校" />
                        <van-cell title="开始时间" is-link @click="SelectDates('data3', 'eduStart1')"
                            :value="dataList.data3.eduStart1" />
                        <van-cell title="结束时间" is-link @click="SelectDates('data3', 'eduEnd1')"
                            :value="dataList.data3.eduEnd1" />
                        <van-cell title="学历" @click="SelectData('data3', 'eduXueli1')" is-link
                            :value="dataList.data3.eduXueli1" />
                        <van-field v-model.trim="dataList.data3.major1" name="major1" label="专业" input-align="right"
                            placeholder="请输入您的专业" />

                        <div style="margin: 16px;">
                            <van-button class="btn" block native-type="submit">下一步</van-button>
                        </div>
                    </van-form>
                </van-tab>
                <van-tab title="工作经历" :disabled="!checkTwo">
                    <p class="tip">按工作的先后顺序填写，时间要连贯 (无业状态也要写明时间和事项)</p>
                    <van-form @submit="onSubmit('data2')">
                        <div class="work_item" v-for="(item, index) in dataList.data2" :key="index">
                            <van-divider content-position="left"
                                :style="{ color: '#02DBCA', borderColor: '#02DBCA', padding: '0 16px', margin: '7px 0' }">
                                工作经历{{ index + 1 }}
                            </van-divider>
                            <van-field v-model.trim="item.place" name="place" label="单位名称" input-align="right"
                                placeholder="请输入您的单位名称" :rules="[{ required: true, message: '' }]" />
                            <van-cell title="开始时间" @click="SelectDates('data2', 'start', index)" is-link
                                :value="item.start" />
                            <van-cell title="结束时间" @click="SelectDates('data2', 'end', index)" is-link
                                :value="item.end" />
                            <van-field v-model.trim="item.zhiwu" name="zhiwu" label="职务" input-align="right"
                                placeholder="请输入工作职务" :rules="[{ required: true, message: '' }]" />
                            <van-field v-model.trim="item.headName" name="headName" label="上级领导" input-align="right"
                                placeholder="请输入上级领导姓名" :rules="[{ required: true, message: '' }]" />
                            <van-field v-model.trim="item.headTel" name="headTel" label="上级电话" input-align="right"
                                placeholder="请输入上级电话" :rules="[{ required: true, message: '' }]" />
                        </div>
                        <div class="operate">
                            <van-button class="add operate_button" size="small" @click="addWork" icon="plus"
                                native-type="button">新增工作经历</van-button>
                            <van-button class="delete operate_button" size="small" @click="deleteWork" icon="delete"
                                native-type="button">删除工作经历
                            </van-button>
                        </div>
                        <p class="tip">请务必根据实际情况填写</p>
                        <div style="margin: 16px;">
                            <van-button class="btn" block native-type="submit">下一步</van-button>
                        </div>
                    </van-form>
                </van-tab>
                <van-tab title="你问我答" :disabled="!checkThree">

                    <van-form @submit="Submit">
                        <van-field v-for="(item, index) in quesList" :key="item.id">
                            <template #input>
                                <div class="options">
                                    <div class="title">{{ index + 1 }}、{{ item.question }}</div>
                                    <van-radio-group v-model="item.answer">
                                        <van-radio icon-size="18px" v-for="r in item.QuOptions" :key="r.opt_code"
                                            :name="r.opt_code">{{ r.opt_code }}、{{ r.opt_content }}</van-radio>
                                    </van-radio-group>
                                </div>
                            </template>
                        </van-field>
                        <!-- <p class="tip" style="margin-right: 10px;font-size: 13px;">本人承诺：入职后，认真学习本岗位《岗位职责》、胜任力要求及其他安排的学习工作任务，并保证学习考核合格，如不合格，将服从公司安排。</p> -->
                        <div class="tip" style="margin-right: 10px; font-size: 13px">
                            <div>
                                <van-checkbox v-model="checked" shape="square"></van-checkbox>
                            </div>
                            <div style="white-space: pre-wrap; margin-left: 10px" v-html="selfPromise"></div>
                        </div>
                        <div class="btn" style="margin: 18px 16px;">
                            <van-button class="btn" block native-type="submit">提交</van-button>
                        </div>
                    </van-form>
                </van-tab>
            </van-tabs>
        </div>
        <!--    下拉框-->
        <van-popup v-model="showPicker" lock-scroll round position="bottom">
            <van-picker title="请选择" :loading="loading" show-toolbar :columns="columns" @confirm="fillData"
                @cancel="showPicker = false" />
        </van-popup>
        <!--    时间选择器-->
        <van-popup v-model="showTimer" lock-scroll round position="bottom">
            <van-datetime-picker v-model="SelectDate" :formatter="formatter" type="date" title="选择年月日"
                :min-date="minDate" :max-date="maxDate" @confirm="fillTime" @cancel="showTimer = false" />
        </van-popup>
        <!--    地区选择器-->
        <van-popup v-model="AreaTimer" lock-scroll round position="bottom">
            <van-area title="选择地区" :area-list="areaList" @cancel="AreaTimer = false" @confirm="fillArea" />
        </van-popup>
    </div>
</template>

<script>
import Info from "../components/PersonalInfo";
import areaList from "../util/area";
import { SubmitData, GetInductionQu,GetCommitment  } from "@/api/interview";

export default {
    name: "InterviewRegistration",
    components: {
        Info,
    },
    data() {
        return {
            active: 0,
            name: "",
            year: new Date().getFullYear(),
            Reg: {
                nameReg: /^[\u4e00-\u9fa5]{2,4}$/,
                telReg: /^1[23456789]\d{9}$/,
                idCardReg: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            },
            minDate: new Date(1960, 0, 1),
            maxDate: new Date(2025, 10, 1),
            loading: false,
            checkOne: false,
            checkTwo: false,
            checkThree: false,
            showPicker: false,
            showTimer: false,
            AreaTimer: false,
            checked:false,
            selfPromise:"",
            columns: [],
            quesList: [],
            areaList: areaList,
            SelectDate: "",
            selectTimeKey: "", //是否为工作经历
            selectOneKey: "", //datalist第一层参数
            selectTwoKey: "", //datalist第二层参数
            localData: {
                gender: ["男", "女"], //性别
                xueli_full_time: ["否", "是"], //全日制
                hunyin: ["未婚", "已婚未育", "已婚已育"], //婚姻
                hukou: ["农业", "非农业"], //户口性质
                zhengzhi: ["中共党员", "入党积极分子", "共青团员", "群众"], //政治
                minzu: [
                    "汉族",
                    "蒙古族",
                    "彝族",
                    "侗族",
                    "哈萨克族",
                    "畲族",
                    "纳西族",
                    "仫佬族",
                    "仡佬族",
                    "怒族",
                    "保安族",
                    "鄂伦春族",
                    "回族",
                    "壮族",
                    "瑶族",
                    "傣族",
                    "高山族",
                    "景颇族",
                    "羌族",
                    "锡伯族",
                    "乌孜别克族",
                    "裕固族",
                    "赫哲族",
                    "藏族",
                    "布依族",
                    "白族",
                    "黎族",
                    "拉祜族",
                    "柯尔克孜族",
                    "布朗族",
                    "阿昌族",
                    "俄罗斯族",
                    "京族",
                    "门巴族",
                    "维吾尔族",
                    "朝鲜族",
                    "土家族",
                    "傈僳族",
                    "水族",
                    "土族",
                    "撒拉族",
                    "普米族",
                    "鄂温克族",
                    "塔塔尔族",
                    "珞巴族",
                    "苗族",
                    "满族",
                    "哈尼族",
                    "佤族",
                    "东乡族",
                    "达斡尔族",
                    "毛南族",
                    "塔吉克族",
                    "德昂族",
                    "独龙族",
                    "基诺族",
                ], //民族
                xueli: [
                    "小学",
                    "初中",
                    "高中",
                    "高职",
                    "大专",
                    "本科",
                    "硕士",
                    "博士",
                ], //学历
                eduXueli1: [
                    "小学",
                    "初中",
                    "高中",
                    "高职",
                    "大专",
                    "本科",
                    "硕士",
                    "博士",
                ], //学历
                emergencyShip: ["父母", "配偶", "子女", "兄弟姐妹"], //家庭成员关系
                infoFromKey: [
                    "微信群",
                    "QQ群",
                    "临沂在线",
                    "赶集网",
                    "智联招聘",
                    "58同城",
                    "齐鲁人才",
                    "Boss",
                    "报纸",
                    "朋友介绍",
                    "宣传海报",
                ], //招聘信息来源在此先写死
              infoFromId: [103008,103007,103006,103005,103004,103002,103003,103009,103010,103012,103011]
            },
            dataList: {
                needsPlanId: 0, //招聘计划id
                data: {
                    //基本信息
                    birthday: "", //生日
                    hunyin: "", //婚姻 传数字!! 0：未婚；1：已婚未育；2：已婚已育
                    // isHasRelative: 0,  // 是否亲属在新明辉工作
                    idAddress: "", //身份证住址
                    identityCardNo: "", //身份证
                    minzu: "", //民族
                    name: "", //姓名
                    needsPlanId: 0, //招聘计划id
                    phone: "", //手机号
                    xueli: "", //学历
                    xueli_full_time: "", //是否全日制 0否1是
                    zhengzhi: "", //政治
                    emergencyMan: "", //紧急联系人
                    emergencyShip: "", //双方关系
                    emergencyTel: "", //紧急电话
                    infoFromKey: "", //信息来源
                    acceptedSalary: "", //可接受薪资
                    expectedSalary: "", //期望薪资
                    gender: "", //性别
                    region: "", //地区
                    realAddress: "", //现住址
                    // sponsor: "",  //推荐人
                    height: "", //身高
                    weight: "", //体重
                    // code: "",
                    // appid: ""
                },
                data1: {
                    //教育信息
                    name1: "",
                    eduStart1: "",
                    eduEnd1: "",
                    eduXueli1: "",
                    major1: "",
                    // code: "",
                    // appid: ""
                },
                data3: {
                    //教育信息
                    name1: "",
                    eduStart1: "",
                    eduEnd1: "",
                    eduXueli1: "",
                    major1: "",
                    // code: "",
                    // appid: ""
                },
                data2: [
                    //工作经历
                    {
                        place: "",
                        start: "",
                        end: "",
                        zhiwu: "",
                        headName: "",
                        headTel: "",
                        // code: "",
                        // appid: ""
                    },
                ],
                openId: "",
            },
            errorTip: {
                gender: "请选择性别",
                birthday: "请选择出生日期",
                zhengzhi: "请选择政治面貌",
                // hukou: "请选择户口性质",
                xueli: "请选择学历",
                xueli_full_time: "请选择是否为全日制",
                hunyin: "请选择婚姻",
                minzu: "请选择民族",
                region: "请选择地区",
                infoFromKey: "请选择信息来源",

                eduStart1: "请选择毕业院校开始时间",
                eduEnd1: "请选择毕业院校结束时间",
                eduXueli1: "请选择毕业院校学历",

                start: "请选择工作单位开始时间",
                end: "请选择工作单位结束时间",
            },
        };
    },
    created() {
        this.$store.dispatch("changeLocation", "面试登记");
        let id = this.$route.query.id;
        this.post = this.$route.query.post;
        console.log(id);
        this.dataList.needsPlanId = id;
        this.dataList.data.needsPlanId = id;
        this.dataList.openId = this.$store.state.info.openId;
        this.getQueList();
        this.commitment();
    },
    methods: {
        //你问我答列表
        async getQueList() {
            this.loading = true;
            let res = await GetInductionQu({
                openId: this.$store.state.info.openId,
            });
            if (res.errcode == 200) {
                this.quesList = res.questions;
            }
            this.loading = false;
        },

        //获取承诺
        async commitment() {
            let res = await GetCommitment({
                openId: this.$store.state.info.openId,
            });
            if (res.errcode==200) {
                this.selfPromise = res.data;
            }
    },
        addWork() {
            let item = {
                place: "",
                start: "",
                end: "",
                zhiwu: "",
                headName: "",
                headTel: "",
            };
            this.dataList.data2.push(item);
        },
        deleteWork() {
            if (this.dataList.data2.length != 1) this.dataList.data2.pop();
        },
        SelectDates(OKey, Tkey, i) {
            this.selectTimeKey = i;
            console.log(OKey, Tkey, i);
            if (Tkey === "birthday") {
                this.minDate = new Date(1960, 0, 1);
                this.maxDate = new Date(this.year - 16, 11, 30);
                this.$nextTick(() => {
                    if (this.dataList.data.birthday !== "") {
                        let arr = this.dataList.data.birthday.split("/");
                        this.SelectDate = new Date(arr[0], arr[1] - 1, arr[2]);
                        this.$forceUpdate();
                    }
                });
            }
            if (Tkey == "eduStart1") {
                //教育开始
                (this.minDate = new Date(1960, 0, 1)),
                    this.$nextTick(() => {
                        if (this.dataList.data1.eduStart1 !== "") {
                            let arr = this.dataList.data1.eduStart1.split("/");
                            this.SelectDate = new Date(
                                arr[0],
                                arr[1] - 1,
                                arr[2]
                            );
                            this.$forceUpdate();
                        }
                    });
                this.maxDate = new Date();
            }
            if (Tkey == "eduEnd1") {
                //教育结束
                if (this.dataList[OKey].eduStart1 !== "") {
                    let arr = this.dataList[OKey].eduStart1.split("/");
                    this.minDate = new Date(arr[0], arr[1], arr[2]);
                } else {
                    this.minDate = new Date(1960, 0, 1);
                }
                this.$nextTick(() => {
                    if (this.dataList.data1.eduEnd1 !== "") {
                        let arr = this.dataList.data1.eduEnd1.split("/");
                        this.SelectDate = new Date(arr[0], arr[1] - 1, arr[2]);
                        this.$forceUpdate();
                    }
                });
                this.maxDate = new Date();
            }
            if (Tkey == "start") {
                //工作经历开始
                (this.minDate = new Date(1960, 0, 1)),
                    (this.maxDate = new Date());
                this.$nextTick(() => {
                    if (this.dataList.data2[i].start !== "") {
                        let arr = this.dataList.data2[i].start.split("/");
                        this.SelectDate = new Date(arr[0], arr[1] - 1, arr[2]);
                        this.$forceUpdate();
                    }
                });
            }
            if (Tkey == "end") {
                //工作经历结束
                if (this.dataList.data2[i].start !== "") {
                    let arr = this.dataList.data2[i].start.split("/");
                    this.minDate = new Date(arr[0], arr[1], arr[2]);
                } else {
                    this.minDate = new Date(1960, 0, 1);
                }
                this.maxDate = new Date();
                this.$nextTick(() => {
                    if (this.dataList.data2[i].end !== "") {
                        let arr = this.dataList.data2[i].end.split("/");
                        this.SelectDate = new Date(arr[0], arr[1] - 1, arr[2]);
                        this.$forceUpdate();
                    }
                });
            }
            this.showTimer = true;
            this.selectOneKey = OKey;
            this.selectTwoKey = Tkey;
        },
        SelectArea(OKey, Tkey) {
            this.AreaTimer = true;
            this.selectOneKey = OKey;
            this.selectTwoKey = Tkey;
        },
        SelectData(OKey, Tkey) {
            this.showPicker = true;
            this.loading = true;
            this.selectOneKey = OKey;
            this.selectTwoKey = Tkey;
            this.columns = this.localData[this.selectTwoKey];
            this.loading = false;
        },
        fillArea(Arr) {
            let area = "";
            Arr.forEach((item) => {
                area += ` ${item.name}`;
            });
            area = area.substr(1);
            this.dataList[this.selectOneKey][this.selectTwoKey] = area;
            this.AreaTimer = false;
        },
        fillData(value, index) {
            this.dataList[this.selectOneKey][this.selectTwoKey] = value;
            this.showPicker = false;
        },
        fillTime(value) {
            let date = new Date(value);
            let TransDate =
                date.getFullYear() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getDate();
            this.selectTimeKey !== undefined
                ? (this.dataList[this.selectOneKey][this.selectTimeKey][
                      this.selectTwoKey
                  ] = TransDate)
                : (this.dataList[this.selectOneKey][this.selectTwoKey] =
                      TransDate);
            this.showTimer = false;
        },
        formatter(type, val) {
            if (type === "year") {
                return val + "年";
            }
            if (type === "month") {
                return val + "月";
            }
            if (type === "day") {
                return val + "日";
            }
            return val;
        },
        onSubmit(oneKey) {
            console.log(1231321123)
            console.log(oneKey, "oneKey");
            this.$toast.allowMultiple();
            console.log(this.dataList, "this.dataList");
            let lock = false;
            if (oneKey != "data2") {
                for (let i in this.dataList[oneKey]) {
                    if (this.dataList[oneKey][i] == "") {
                        lock = true;
                        for (let k in this.errorTip) {
                            if (i == k) {
                                this.$notify({
                                    type: "warning",
                                    message: this.errorTip[k],
                                    duration: 1500,
                                });
                                console.log(this.errorTip[k],'this.errorTip[k]')
                            }
                        }
                    }
                }
            }
            console.log(123,lock)
            if (lock) return;
            if (oneKey == "data") {
                this.checkOne = true;
                this.$nextTick(() => {
                    this.active = 1;
                });
            }
            if (oneKey == "data1") {
                this.checkTwo = true;
                this.$nextTick(() => {
                    this.active = 2;
                });
            }

            if (oneKey == "data2") {
                this.dataList[oneKey].forEach((item) => {
                    for (let i in item) {
                        if (item[i] == "") {
                            lock = true;
                            for (let k in this.errorTip) {
                                if (i == k) {
                                    this.$notify({
                                        type: "warning",
                                        message: this.errorTip[k],
                                        duration: 1500,
                                    });
                                }
                            }
                        }
                    }
                });
            }
            if (lock) return;
            if (oneKey == "data2") {
                this.checkThree = true;
                this.$nextTick(() => {
                    this.active = 3;
                });
            }
        },
        async Submit() {
            if (!this.quesList.every((item) => item.answer)) {
                this.$notify({
                    type: "warning",
                    message: "小问题还没有答完哦~",
                });
                return;
            }
            if (!this.checked) {
                this.$notify({
                type: "warning",
                message: "请勾选承诺~",
                });
                return;
            }
            this.$toast.allowMultiple();
            let loading = this.$toast.loading({
                message: "提交信息中...",
                forbidClick: true,
                duration: 0,
            });
            //追加你问我答
            let arr = this.quesList.map((item) => {
                console.log(item, "item");
                const { id, answer } = item;
                return {
                    quesId: id,
                    answer,
                    questions: item.question,
                };
            });
            this.dataList.answer = JSON.stringify(arr);
            //数字处理
            this.dataList.data.gender =
                this.dataList.data.gender == "女" ? 1 : 0;
            if(this.dataList.data.xueli_full_time){
              this.dataList.data.xueli_full_time = this.dataList.data.xueli_full_time === '否' ? 0 : 1
            }
            this.dataList.data.hunyin =
                this.dataList.data.hunyin == "未婚"
                    ? 0
                    : this.dataList.data.hunyin == "已婚未育"
                    ? 1
                    : 2;
            const infoKey = this.dataList.data.infoFromKey;
            const index = this.localData.infoFromKey.findIndex(self => self === infoKey)
            this.dataList.data.infoFromKey = this.localData.infoFromId[index]
            console.log(this.dataList, "this.dataList");
            // return
            //表单提交
            await SubmitData(this.dataList).then((res) => {
                loading.clear();
                if (res.errcode == 200) {
                    this.$toast.success("提交成功");
                    setTimeout(() => {
                        this.$router.push({
                            name: "Search",
                        });
                    }, 1000);
                } else {
                    this.$toast.fail({
                        message: res.message,
                        forbidClick: true,
                        duration: 3000,
                    });
                    this.dataList.data.gender =
                        this.dataList.data.gender == 1 ? "女" : "男";
                  if(this.dataList.data.xueli_full_time){
                    this.dataList.data.xueli_full_time = this.dataList.data.xueli_full_time === '否' ? 0 : 1
                  }
                    this.dataList.data.hunyin =
                        this.dataList.data.hunyin == 0
                            ? "未婚"
                            : this.dataList.data.hunyin == 1
                            ? "已婚未育"
                            : "已婚已育";
                  this.dataList.data.infoFromKey = infoKey;
                }
            });
            loading.clear();
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    padding-top: 5px;

    .tab {
        margin-top: 5px;

        .btn {
            background-color: #02dbca;
            border-radius: 6px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
        }

        .tip {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ff0000;
            box-sizing: border-box;
            display: flex;
            padding: 20px;
            }

        .operate {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;

            .operate_button {
                width: 136px;
                height: 34px;
                background-color: #fff;
                border-radius: 4px;
            }

            .add {
                color: #02dbca;
                border: 1px solid #02dbca;
                margin-right: 13px;
            }

            .delete {
                color: #cc6600;
                border: 1px solid #cc6600;
            }
        }
    }
}

.options {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-bottom: 10px;
    }

    .van-radio-group {
        letter-spacing: 2px;
        padding: 0 10px;
        box-sizing: border-box;
        font-weight: 500;

        .van-radio {
            display: flex;
            align-items: center;
            height: 40px;
            line-height: 40px;
        }
    }
}

.xlTitle {
    font-weight: bold;
    box-sizing: border-box;
    padding: 10px 16px;
}
</style>
